import {IconButton, Paper, Toolbar, withStyles, Grid} from '@material-ui/core';
import debounce from 'lodash.debounce';
import React, {Component} from 'react';
import {rwbApi} from '../../../../shared/apis/api';
import {
  EXECUTION_STATUS,
  logEditComment,
  logEditReply,
  logFeedCreateComment,
  logFeedCreateReply,
  logIncludeOpenGraph,
  logTagUser,
  webSectionName,
} from '../../../../shared/models/Analytics';
import {
  isNullOrEmpty,
  validTaggedUsers,
  getHyperlinks,
  validURL,
  capitalizeFirstLetter,
  filterPostMedia,
} from '../../../../shared/utils/Helpers';
import Loading from '../Loading';
import PostIcon from '../svgs/PostIcon';
import XIcon from '../svgs/XIcon';
import TextArea from '../TextArea';
import styles from './CreateComment.module.css';
import FormattedPostText from './FormattedPostText';
import ShareChallengeBox from './ShareChallengeBox';
import UsersList from './UsersList';
import SitePreviewCard from '../cards/SitePreviewCard';
import { MAX_COMMENT_LENGTH } from '../../../../shared/constants/Restrictions';
import { CreationPostImageDisplay } from './PostImageDisplay.react';
import PostImageAdder from './PostImageAdder.react';
import imageHandler from '../ImageHandler.react';
import { REACTION_TYPES } from '../../../../shared/utils/StreamHelpers';

const DEBOUNCE_MS = 500;

const _styles = {
  root: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'var(--magenta)',
    height: 64,
  },
};

class CreateComment extends Component {
  constructor(props) {
    super(props);
    this.reactionType = this.props.reactionType || REACTION_TYPES.COMMENT;
    let initialCommentText;
    let initialTagged;
    if (this.props?.replyData?.taggedUser) {
      initialCommentText = `@${this.props.replyData?.taggedUser?.first_name} ${this.props.replyData?.taggedUser?.last_name}`;
      initialTagged = [this.props.replyData.taggedUser];
    }
    else if (this.props.posterText) initialCommentText = this.props.text;
    this.state = {
      isLoading: false,
      commentText: initialCommentText || '',
      taggedUsers: initialTagged || this.props.tagged || [],
      atLocation: null,
      searchingUsers: false,
      userSearchLoading: null,
      userResults: [],
      links: this.props.posterText
        ? getHyperlinks(this.props.posterText)
        : getHyperlinks(this.props.text),
      commentLinks: this.props.posterText ? getHyperlinks(this.props.text) : [],
      canceledLinkView: null,
      previewData: this.props.graphData || null,
      postImages: filterPostMedia(this.props?.comment?.data?.media, 'image') || [],
    };
  }

  apiUserSearch = (text) => {
    rwbApi.searchUser(text).then((result) => {
      this.setState({userResults: result, userSearchLoading: false});
    });
  };

  handleTextInput = (text) => {
    const {searchingUsers, atLocation} = this.state;
    // TODO: Handle backspacing early in the post while searching for users
    // TODO: Highlight color of valid user, could not find colors specific parts of text input while making it editable.
    // Once this is determined, validTaggedUsers could be modified to display valid users with the proper color
    this.setState({commentText: text});
    // elastic search with value after @
    if (text.charAt(text.length - 1) === '@' && !searchingUsers) {
      this.setState({searchingUsers: true, atLocation: text.length});
    }

    if (searchingUsers) {
      // if the user deletes the "@" symbol, stop searching
      if (text.charAt(atLocation - 1) !== '@') {
        this.setState({searchingUsers: false, atLocation: null});
      } else this.searchUser(text.slice(atLocation));
    }

    const commentLinks = getHyperlinks(text);
    const state = {};
    if (commentLinks?.length) {
      state.commentLinks = commentLinks;
      if (
        this.state.canceledLinkView &&
        commentLinks[commentLinks.length - 1] !== this.state.canceledLinkView &&
        validURL(commentLinks[commentLinks.length - 1])
      ) {
        state.canceledLinkView = null;
      }
    } else {
      if (this.state.canceledLinkView) {
        state.canceledLinkView = null;
      }
      state.commentLinks = [];
    }
    this.setState(state);
  };

  updateOptions = debounce(this.apiUserSearch, DEBOUNCE_MS);

  searchUser = (text) => {
    this.setState({inputText: text, userSearchLoading: true});
    this.updateOptions(text);
  };

  handleSelectedUser = (user) => {
    // access tagged users without mutating the array directly
    let taggedUsers = [...this.state.taggedUsers];
    let commentText = this.state.commentText;
    const name = user.name;
    user.first_name = name.split(' ').slice(0, -1).join(' ');
    user.last_name = name.split(' ').slice(-1).join(' ');
    taggedUsers.push(user);
    commentText = commentText.replace(this.state.inputText, user.name);
    this.setState({
      searchingUsers: false,
      userResults: [],
      taggedUsers,
      commentText,
    });
  };

  baseAnalyticsObj = () => {
    const obj = {
      section_name: webSectionName(),
    };
    const {
      eventID, 
      event, 
      group, 
      groupID, 
      challenge, 
      challengeID, 
      groupRecordType, 
      group_record_type, 
      activity_sub_type, 
      event_record_type, 
      challengeId, 
      feed_origin_name, 
      feed_origin_type, 
      streamID, 
      commentID
    } = this.props;
    if (feed_origin_name) {
      obj.feed_origin_name = feed_origin_name;
    }
    if (feed_origin_type) {
      obj.feed_origin_type = feed_origin_type;
    }
    if (event?.id && event.id != 0) {
      obj.event_id = `${event.id}`;
    } else if (eventID) obj.event_id = `${eventID}`;
    if (group?.id && group.id != 0) {
      obj.group_id = `${group.id}`;
      obj.group_record_type = group?.type; // double check
    } else if (groupID) obj.group_id = `${groupID}`;
    if (challenge?.id && challenge?.id != 0) {
      obj.challenge_id = `${challenge?.id}`;
    } else if (challengeID) obj.challenge_id = challengeID;
    else if (challengeId) obj.challenge_id = challengeId;
    if (groupRecordType) obj.group_record_type = groupRecordType;
    else if (group_record_type) obj.group_record_type = group_record_type;
    if (event_record_type) obj.event_record_type = event_record_type;
    if (activity_sub_type) obj.activity_sub_type = activity_sub_type;
    if (streamID) obj.object_post = streamID;
    if (commentID) {
      obj.object_comment = commentID;
    }
    return obj;
  };

  commentLogger = (taggedUsers, commentText, analyticsObj) => {
    // create comment receives tagged users from the parent post
    // this needs to be looked into if that is needed or was a mistake to add
    const validatedTaggedUsers = validTaggedUsers(taggedUsers, commentText);
    const includeOpenGraph = this.checkIncludeOpenGraph();
    if (validatedTaggedUsers.length > 0) logTagUser(analyticsObj);
    if (includeOpenGraph) logIncludeOpenGraph(analyticsObj);

    if (this.props.posterText) {
      if (this.reactionType === REACTION_TYPES.COMMENT) {
        logEditComment(analyticsObj);
      } else if (this.reactionType === REACTION_TYPES.REPLY) {
        logEditReply(analyticsObj);
      }
    } else {
      if (this.reactionType === REACTION_TYPES.COMMENT) {
        logFeedCreateComment(analyticsObj);
      } else if (this.reactionType === REACTION_TYPES.REPLY) {
        logFeedCreateReply(analyticsObj);
      }
    }
  };

  checkIncludeOpenGraph = () => {
    const {previewData, commentLinks, canceledLinkView} = this.state;
    return (
      previewData &&
      commentLinks?.length &&
      (!canceledLinkView ||
        canceledLinkView !== commentLinks[commentLinks.length - 1])
    );
  };

  submitComment = async () => {
    const {commentText, taggedUsers, postImages} = this.state;
    const {poster, streamID, closeModalHandler} = this.props;
    const validatedTaggedUsers = validTaggedUsers(taggedUsers, commentText);

    if (isNullOrEmpty(commentText)) {
      alert(`Unable to post an empty ${this.reactionType}.`);
      return;
    }

    this.setState({isLoading: true});
    let analyticsObj = this.baseAnalyticsObj();
    let data = {
      kind: this.reactionType,
      content: commentText,
      tagged: validatedTaggedUsers,
    };
    const {previewData} = this.state;
    const includeOpenGraph = this.checkIncludeOpenGraph();
    if (includeOpenGraph) {
      data.open_graph = {
        description: previewData.description,
        image_url: previewData.image,
        title: previewData.title,
        url: previewData.url,
      };
    }
    if (postImages.length) {
      this.setState({isLoading: true});
      try {
       data.media = await imageHandler(postImages, this.reactionType);
      } catch (error) {
        console.warn('error getting image urls: ', error);
      }
    }
    // TODO: Check this, poster text is the text of the post. We might be omitting it in some cases which is less clear
    if (this.props.posterText) {
      if (this.reactionType === REACTION_TYPES.REPLY && this.props.commentID) {
        data.comment_id = this.props.comment.id; // reply
        if (this.props.offset) data.offset = this.props.offset; // comment id when loading previous comments
        analyticsObj.object_comment = this.props.commentID;
        analyticsObj.object_reply = this.props.comment.id;
      }
      rwbApi
        .updateReaction(
          poster.id,
          streamID,
          JSON.stringify(data),
          this.props.comment.id,
        )
        .then((result) => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
          this.setState({isLoading: false});
          let commentData = result.data;
          commentData.tagged = taggedUsers;
          if (this.reactionType === REACTION_TYPES.COMMENT){ 
          } else if (this.reactionType === REACTION_TYPES.REPLY) {
            analyticsObj.object_reply = result?.id;
          }
          closeModalHandler(false, commentData);
        })
        .catch((err) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          this.setState({isLoading: false});
          alert('Unable to update your comment. Please try again later.');
        })
        .finally((e) => {
          this.commentLogger(taggedUsers, commentText, analyticsObj);
        });
    } else {
      if (this.reactionType === REACTION_TYPES.REPLY && this.props.commentID) {
        data.comment_id = this.props.commentID;
        analyticsObj.object_comment = this.props.commentID;
        if (this.props.offset) data.offset = this.props.offset; // comment id when loading previous comments
        if (this.props?.reaction?.id && this.props?.reaction?.id !== this?.props?.commentID) data.reply_id = this.props.reaction.id; // reply being replied to
      }
      rwbApi
        .postReaction(poster.id, streamID, JSON.stringify(data))
        .then((result) => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
          if (this.reactionType === REACTION_TYPES.COMMENT) {
            analyticsObj.object_comment = result?.reaction?.id;
          }
          else if (this.reactionType === REACTION_TYPES.REPLY) {
            analyticsObj.object_reply = result?.reaction?.id;
          }
          this.setState({isLoading: false});
          if (this.props.handleCommentCreation) this.props.handleCommentCreation();
          closeModalHandler(true);
        })
        .catch((err) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          this.setState({isLoading: false});
          alert(`Unable to post your ${this.reactionType}. Please try again later.`);
        }).finally((finalResult) => {
          this.commentLogger(taggedUsers, commentText, analyticsObj);
        });
    }
  };

  storePreviewData = (data) => {
    this.setState({previewData: data});
  };

  render() {
    const {
      classes,
      closeModalHandler,
      poster,
      verbEvent,
      time,
      text,
      tagged,
      history,
      postImage,
      graphData,
      postGraphData,
      commenter,
    } = this.props;
    const {
      isLoading,
      commentText,
      searchingUsers,
      userResults,
      userSearchLoading,
      links,
      commentLinks,
      canceledLinkView,
      previewData,
      postImages,
    } = this.state;
    return (
      <div className={styles.container}>
        <Loading size={100} color={'var(--white)'} loading={isLoading} />
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              onClick={() => closeModalHandler(false)}
              className={classes.menuButton}
              color="inherit">
              <XIcon tintColor="var(--white)" />
            </IconButton>
            <p className="title">{capitalizeFirstLetter(this.reactionType)}</p>
            <IconButton
              onClick={this.submitComment}
              className={classes.menuButton}
              color="inherit">
              <PostIcon />
            </IconButton>
          </Toolbar>
        </Paper>

        <div className={styles.content}>
          <div className={styles.postContainer}>
            <div className={styles.namePostContainer}>
              <div>
                <span className="namesAndObjects">
                  {/* poster is the parent, when editing a reply we always want the comment while when creating a reply we want the reply/comment the reply is going to (commenter) */}
                  {(this.reactionType === REACTION_TYPES.COMMENT || this.props.text) ? `${poster?.first_name} ${poster?.last_name}` : `${commenter?.first_name} ${commenter?.last_name}`}&nbsp;
                </span>
                {verbEvent}&nbsp;•&nbsp;{time}
              </div>

              <div className={styles.textImageContainer}>
                {this.props?.workout?.eventName ? (
                  <ShareChallengeBox
                    eventName={this.props.workout.eventName}
                    chapterName={this.props.workout.chapterName}
                    eventStartTime={this.props.workout.eventStartTime}
                    miles={this.props.workout.miles}
                    steps={this.props.workout.steps}
                    hours={this.props.workout.hours}
                    minutes={this.props.workout.minutes}
                    seconds={this.props.workout.seconds}
                  />
                ) : null}
                <FormattedPostText
                  text={this.props.posterText || text}
                  tagged={tagged}
                  linkableUsers={false}
                  history={history}
                  clickable={true}
                  links={links}
                />
                {links.length && (postGraphData?.url || graphData?.url) ? (
                  <SitePreviewCard
                    key={links[links.length - 1]}
                    link={links[links.length - 1]}
                    // postGraphData only gets passed in when editing comment, postGraphData is post's graph data and graphData is comment's graph data
                    // when creating post, graphData is the post's graph data instead and postGraphData will be null
                    graphData={postGraphData || graphData}
                  />
                ) : null}
                {postImage && (
                  <img
                    className={styles.postImage}
                    src={postImage}
                    alt="User Post Image"
                    onClick={() => this.setState({isModalOpen: true})}
                  />
                )}
              </div>
            </div>
          </div>
          <TextArea
            placeholder={`Add your ${this.reactionType}`}
            value={commentText}
            onChange={this.handleTextInput}
            autoFocus={!commentText}
            taggedUsers={this.state.taggedUsers}
            maxCharacters={MAX_COMMENT_LENGTH}
          />
          {!canceledLinkView &&
          commentLinks.length &&
          validURL(commentLinks[commentLinks.length - 1]) ? (
            <Grid container spacing={2} className={styles.linkPreviewContainer}>
              <Grid item xs={6} key={commentLinks[commentLinks.length - 1]}>
                <SitePreviewCard
                  key={commentLinks[commentLinks.length - 1]}
                  link={commentLinks[commentLinks.length - 1]}
                  cancelable={true}
                  onClose={() => {
                    this.setState({
                      canceledLinkView: commentLinks[commentLinks.length - 1],
                    });
                  }}
                  storePreviewData={this.storePreviewData}
                  graphData={previewData}
                />
              </Grid>
            </Grid>
          ) : null}
          {searchingUsers && (
            <div className={styles.usersListContainer}>
              <UsersList
                usersSuggestions={userResults}
                loadingUsers={userSearchLoading}
                onSelect={this.handleSelectedUser}
              />
            </div>
          )}
        </div>
        <CreationPostImageDisplay postImages={postImages} updatePostImages={(images) => this.setState({postImages: images})}/>
        <PostImageAdder postImages={postImages} updatePostImages={(images) => this.setState({postImages: images})} activityType={this.reactionType}/>
      </div>
    );
  }
}

export default withStyles(_styles)(CreateComment);
